/**
 * Envelope element
 */

.enveloppe {
  background: $light;
  border-radius: $border-radius;

  .flap {
    width: 100%;
    height: 120px;
    color: #fff;

    background-size: 100% 100%;
    border-radius: $border-radius $border-radius 0 0;
    display: flex;
    flex-direction: column;

    .flap-content {
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
    }

    @each $name, $color in $theme-colors {
      &.flap-#{$name} {
        background: $color;
      }
    }

    .flap-bottom {
      display: flex;
      height: 32px;
      margin-bottom: -16px;
      width: 100%;
      flex-direction: row;

      &:after,
      &:before {
        content: "";
        display: flex;
        width: 51%;
        height: 100%;
        background: $light;
      }

      &:before {
        transform: skew(0deg, 5deg);
      }
      &:after {
        transform: skew(0deg, -5deg);
      }
    }
  }
}
