/**
 * Buttons
 *
 * Adds functionality to the base bootstrap buttons element
 */

 .button-outline-primary {
  background-color: $white;
  color: $dark;
  border-width: 1px;
  border-color: $secondary;
 }

 .button-outline-primary:hover {
  background-color: $secondary;
  color: white;
 }

 #button-white-text {
   color: white !important;
 }
