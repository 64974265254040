/**
 * Hidden
 *
 * Will hide an element, but not using !important; Use this when an element
 * has to be dynamically readded later.
 */

.hidden {
  display: none;
}
