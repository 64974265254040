/**
 * Main lendahand.com application file, this file only references other scss
 * seperate from mail.scss which contains mail css.
 */

// Import bootstrap and variables first

@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/bootstrap";

// Import other css dependencies
@import "~featherlight/release/featherlight.min";
@import "~nouislider/dist/nouislider.min.css";
@import "~select2/src/scss/core";
@import "~select2-bootstrap4-theme/dist/select2-bootstrap4.min";
@import "~slick-carousel/slick/slick.css";
@import "~shepherd.js/dist/css/shepherd";

// Import app specific less files
@import "lendahand-icon-font";
@import "mixins";
@import "fonts";
@import "structure";
@import "elements";
@import "utilities";
@import "typography";

// Import vendor overrides
@import "overrides";

// Import layouts
@import "layouts";
