.number-card {
  $ref: &;

  &:not(.flat) {
    padding: 16px 20px 16px 20px;
    background: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 16px;
  }

  &__number {
    color: $teal;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 120% */
    font-weight: $font-weight-bold;
  }

  &__number-suffix {
    color: $teal;
    font-size: 16px;
    line-height: 24px; /* 150% */
    font-weight: $font-weight-bold;
  }

  &__label {
    display: inline-block;
    color: $gray-600;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    &.underline {
      position: relative;
      cursor: help;
      &:after {
        position: absolute;
        bottom: 2px;
        left: 0px;
        right: 0px;
        content: "";
        display: block;
        border-bottom: 1px dashed $gray-400;
      }
    }
  }

  &.small {
    &:not(.flat) {
      padding: 8px 10px 4px 10px;
      border-radius: 8px;
    }
    #{$ref}__number {
      color: $deep-green;
      font-size: 18px;
      line-height: 28px; /* 155.556% */
    }
    #{$ref}__number-suffix {
      color: $deep-green;
      font-size: 12px;
      line-height: 18px; /* 150% */
    }
    #{$ref}__label {
      font-size: 12px;
      line-height: 18px; /* 150% */
      font-weight: $font-weight-medium;
    }
  }
}
