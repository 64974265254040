/**
 * Overrides for the noUi slider
 */

.noUi-target {
  height: 4px;
  .noUi-base {
    height: 4px;
    .noUi-connect {
      background: $dark;
      height: 16px;
    }
  }
  .noUi-tooltip {
    top: 240%;
    bottom: unset;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .noUi-handle {
    height: 16px;
    width: 16px;
    border-style: solid;
    border-color: $dark;
    border-width: 4px;
    border-radius: 50%;
  }
  .noUi-touch-area {
    height: 4px;
  }
}
