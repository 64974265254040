// We want to show some larger tooltips, besides the default ones

.popover {
  &__extended {
    a:not(.btn),
    .text-link,
    .text-light {
      color: $teal !important;
      text-decoration: underline;
      &:hover {
        color: $teal-700 !important;
      }
    }
    h2,
    h3,
    h4 {
      color: $teal;
    }
    .arrow {
      margin-top: -1px;
      &:before {
        border-top-color: $green-900 !important;
      }
      &:after {
        bottom: 1.5px !important;
        border-top-color: white !important;
      }
    }
    .bs-popover-bottom[x-placement^="bottom"] {
      .arrow {
        margin-top: 0;
        margin-bottom: -1px;
        &:before {
          border-bottom-color: $green-900 !important;
        }
        &:after {
          top: 1.5px !important;
          border-bottom-color: white !important;
        }
      }
    }
    .popover-header {
      &:before {
        display: none !important;
      }
    }
    .popover-body {
      padding: 0 8px;
      @extend .text-small;
    }
    .popover {
      width: 300px;
      max-width: 300px !important;
      background-color: white;
      border-color: $dark;
      box-shadow: $smooth-shadow-sm;
      padding: 1rem;
      & > * {
        color: $dark;
        background-color: transparent;
        border: none;
      }
      &.bs-popover-auto[x-placement^="bottom"] {
        & > .arrow:before,
        & > .arrow:after {
          border-bottom-color: $dark;
        }
      }
      &.bs-popover-auto[x-placement^="top"] {
        & > .arrow:before,
        & > .arrow:after {
          border-top-color: $dark;
        }
      }
      &.bs-popover-auto[x-placement^="left"] {
        & > .arrow:before,
        & > .arrow:after {
          border-left-color: $dark;
        }
      }
      &.bs-popover-auto[x-placement^="right"] {
        & > .arrow:before,
        & > .arrow:after {
          border-right-color: $dark;
        }
      }
    }
  }
}
