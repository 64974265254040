/**
 * Used to add notifications to the bottom of the page
 */

.notifications {
  width: 100%;
  display: block;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  color: $white;
  background-color: $danger;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

  z-index: 1000;

  a,
  a:hover,
  a:active,
  a:visited {
    color: #fff;
  }
}
