/**
 * Number slider element. To be used with the input-slider, maturity-slider or
 * interest slider behaviors
 */

.number-slider {
  display: block;
  width: 100%;
  min-height: 60px;
}
