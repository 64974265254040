.home-hero {
  &__key-visual {
    padding-top: 44px;
    padding-bottom: 44px;
    margin-bottom: 20px;
    background: {
      image: url("images/backgrounds/background-light-5.svg");
      color: $gray-light;
      repeat: no-repeat;
      size: cover;
      position: center;
    }
    @include media-breakpoint-up(sm) {
      text-align: center;
      padding-top: 80px;
      padding-bottom: 80px;
      background: {
        size: 200% auto;
      }
    }
    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-bottom: 0;
      background: {
        image: var(--background-image);
        size: cover;
      }
    }
    @include media-breakpoint-up(xl) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }
  &__call-to-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    padding: 0;
    @include media-breakpoint-down(sm) {
      margin-top: 32px;
      & .btn {
        display: block;
      }
    }
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: center;
    }
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }
}
