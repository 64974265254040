/**
 * This file includes all HTML elements / overrides
 */

li {
  border-radius: 1px;
  border-width: 1px;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.container.fat-container {
  max-width: 1400px;
}

.plain-link a {
  color:#0a3734;
  text-decoration: underline;
}