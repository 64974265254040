/**
 * Adds some extra styles to the default input field.
 */

.form-control {
  &.with-icon {
    padding-left: 40px !important;
  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    color: $gray-600;
  }
}
