.customer-reviews {
  &__average-score {
    color: $coral;
    font-weight: 800;
    font-size: 14rem;
    text-align: center;
    line-height: 1.2;
    letter-spacing: -0.05em;
    @include media-breakpoint-up(sm) {
      font-size: 16rem;
    }
  }
}
