/**
 * Simple utility classes to add cursor rules to an element
 */

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}
