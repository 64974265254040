.offer {
  $ref: &;
  background-color: white;
  &__container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  &__row {
    display: grid;
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__card {
    border: 1px solid;
    border-radius: 8px;
    &:not(.solidarity) {
      border-color: $gray-400;
    }
    &.solidarity {
      border-color: $coral;
    }
    border-color: $gray-400;
    &:focus-within {
      box-shadow: $smooth-shadow-sm;
      border-color: $deep-green;
      background-color: lighten($gray-light, 3%) !important;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
  }
  &__details {
    border-top: 1px solid $gray-400;
  }
  &__detail-item {
    // Required for text teruncate to work
    min-width: 0;
    @include media-breakpoint-up(md) {
      flex: 1 0 0;
      &:not(:last-child) {
        border-right: 1px solid $gray-400;
      }
    }
  }
  &__intro {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 400;
  }
  &__currency-tag {
    right: 16px;
    top: 16px;
    padding: 6px 12px 6px 6px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 24px;
  }
  &__image {
    position: relative;
    min-height: 224px;
    overflow: hidden;
    & > img {
      position: absolute;
      top: 8px;
      left: 8px;
      right: 8px;
      bottom: 8px;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      object-fit: cover;
      border-radius: 2px;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    &.featured {
      order: 2;
    }
  }
  &__fundraising {
    order: 1;
  }
  &__countdown {
    padding: 12px 12px 12px 8px;
    color: $green-900;
    font-weight: $font-weight-medium;
    border: 1px solid $green-200;
    border-radius: 2px;
    background-color: $green-100;
    i {
      margin-right: 8px;
      color: $green-600;
    }
  }
  &--featured {
    display: flex;
    flex-direction: column;
    & #{$ref}__action {
      order: 3;
    }
  }
}

.currency {
  &__label {
    color: $teal;
    font-size: 12px;
    line-height: 18px;
    font-weight: $font-weight-bold;
  }
  &__eur {
    color: $teal;
    background-color: $teal-light;
  }
  &__usd {
    color: $deep-green;
    background-color: $deep-green-light;
  }
}

.project-tags {
  bottom: 0;
  left: 0;
  color: $dark;
  &:hover {
    color: inherit;
  }
}

.project-tag {
  padding: 6px 12px 6px 16px;
}

.detail-item {
  &__title {
    font-size: 18px;
    line-height: 20px;
  }
  &__label {
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
  }
}

.investors-grid {
  display: flex;
  flex-wrap: wrap;
  &__image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    object-fit: cover;
    &:not(:last-child) {
      margin-right: -8px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .project-card {
    display: none;
  }

  .project-card-mobile {
    display: block;
  }

  .project-overlay {
    display: none;
  }

  .select-amount {
    display: none;
  }
  .detail-item {
    &__title {
      font-size: 1rem;
      line-height: 1.2;
    }
  }
}

@include media-breakpoint-up(lg) {
  .offer {
    &--featured {
      padding: 8px;
    }
  }
}

@include media-breakpoint-up(md) {
  .offer {
    $ref: &;
    &__body {
    }
    &--featured {
      flex-direction: row;
      gap: 16px;
      & > #{$ref}__image {
        flex: 0 0 40%;
        padding-right: 0;
      }
      & > #{$ref}__body {
        flex: 1 1 60%;
        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 0;
      }
      & #{$ref}__tags.featured {
        order: 0;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .project-card-mobile {
    display: none;
  }

  .project-overlay {
    z-index: 9999;
  }

  .project-overlay-mobile {
    display: none;
  }

  /* .select-amount-mobile {
    display: none;
  } */
}
