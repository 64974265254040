.offer-tag {
  $ref: &;
  @extend .font-weight-medium;
  @extend .text-small;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 10px 4px 8px;
  border-radius: 2px;
  cursor: default;

  &--diversifies-portfolio {
    background-color: $teal-100;
    color: $teal-600;
    #{$ref}__icon {
      color: $teal-500;
    }
  }
  &--already-invested,
  &--already-invested-business {
    background-color: $green-100;
    color: $green-600;
    #{$ref}__icon {
      color: $green-500;
    }
  }
  &--social_loan {
    background-color: rgba($coral, 0.1);
    color: $coral;
  }
  &--dollar-social_loan {
    background-color: #ece4d4;
    color: #2a593f;
  }
  &--partner_project {
    // using explicit colours here to avoid confusion with the other tags
    // Specifically because the teal described in the spec isn't a 100% match
    background-color: #f8f9fa;
    color: #01919b;
  }

  &--general-label {
    background-color: #f2f2f2;
    color: #0a3734;
  }
}
