.offer-page {
  $ref: &;
  &__layout {
    display: grid;
    // vertical gap: 24px, no horizontal gap
    gap: 32px 0;
    @include media-breakpoint-up(lg) {
      gap: 40px 24px;
      grid-template-columns: minmax(auto, 678px) minmax(auto, 434px);
      align-items: start;

      #{$ref}__header {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      section {
        grid-column: 1;
      }
    }
  }
  &__header {
    position: relative;
    order: -4;
    padding: 40px 0 0;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: -140px;
      width: 100vw;
      transform: translateX(-50vw);
      background-color: $gray-150;
      z-index: -1;
    }
  }
  &__tabs {
    position: sticky;
    top: 0;
    z-index: 1 !important;
    background-color: $gray-150;
    &.sticking {
      z-index: $zindex-fixed + 1 !important;
    }
    & > ul {
      max-width: 1172px;
      margin: 0 auto;
    }
  }
  &__container {
    /*
      We want a more fluid container than the default Bootstrap container to leverage the full width
      of the screen, so we override the default Bootstrap container styles.
    */
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    @include media-breakpoint-up(sm) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @include media-breakpoint-up(md) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
  &__body {
    grid-column: 1;
    order: -1;
  }
  &__intro {
    @include media-breakpoint-up(lg) {
      max-width: 576px;
    }
  }
  .separator {
    border-bottom: 1px solid $gray-200;
  }
  &__sidebar {
    order: -2;
    z-index: 10;
    .separator {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      position: sticky;
      top: 96px;
      min-height: 514px;
      grid-row: 2 / 4;
      grid-column: 2;
      .separator {
        display: block;
      }
    }
  }
  &__sidebar-inner {
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: white;
      border: 1px solid $gray-200;
      border-radius: 16px;
    }
  }
  &__image {
    grid-column: 1;
    order: -3;
    img {
      border-radius: 8px;
    }
  }
  &__choose-amount {
    // .mt-sm-4.mt-lg-0.mx-0.py-2.py-lg-1
    display: block;
    position: fixed;
    bottom: 18px;
    left: 12px;
    right: 12px;
    width: calc(100% - 24px);
    padding-top: 12px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 18px);
    padding-bottom: 12px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 600;
    z-index: 3000;
    @include media-breakpoint-up(sm) {
      margin-top: 24px;
    }
    @include media-breakpoint-up(lg) {
      position: static;
      width: 100%;
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: 500;
    }
  }

  &__card-grid {
    display: grid;
    gap: 12px;
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
  &__card {
    position: relative;
    padding: 16px;
    border: 1px solid $gray-200;
    border-radius: 16px;
    text-decoration: none;
    &[href] {
      text-decoration: none;
      &:hover {
        background-color: $gray-100;
      }
    }
  }
  &__card-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__sdg-grid {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
  }

  &__summary-box-grid {
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr;
      align-items: start;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr;
    }
    h3 {
      width: 100%;
    }
    .offer-page__company-box {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
    }
    .company-row {
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }
  }

  &__box-grid {
    display: grid;
    gap: 8px;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr;
      align-items: start;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__key-statistics-grid {
    display: grid;
    gap: 8px;
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__login-wall {
    padding: 32px 24px;
    border: 1px solid $gray-200;
    border-radius: 16px;
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
  }

  /*
    This is baked into a helper in framework, so for sanity's sake we'll just override it here.
  */
  .img-icon {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
    &.pr-1 {
      padding: 0 !important;
    }
  }
}
