.link-card {
  display: flex;
  gap: 10px;
  background: $gray-100;
  border: 1px solid $gray-200;
  border-radius: 8px;

  padding: 12px 16px;

  @include media-breakpoint-up(md) {
    gap: 16px;
    padding: 20px 16px 20px 24px;
  }

  &:hover {
    outline: none;
    border-color: $gray-400;
    text-decoration: none;
    background-color: darken($gray-100, 2%) !important;
  }

  &:focus {
    outline: none;
    box-shadow: $smooth-shadow-sm;
    border-color: $deep-green;
    background-color: darken($gray-100, 2%) !important;
  }

  &__body {
    flex: 1;
  }
  &__icon {
    flex-shrink: 0;
    color: $teal;
  }
  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $teal;
  }
  &__description {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;
  }
}
