/**
 * Mixins.less
 * Use to provide mixins for other less files
 */

/**
 * Todo: this should be split into seperate files in the mixins/directory
 */

/**
 * Mixin to define a navbar cta-variant
 */

@mixin cta-variant($background-color, $color, $hover-bg: $navbar-light-hover-color, $hover-color: $color) {
  background-color: $background-color;
  color: $color;

  &:hover {
    background-color: $hover-bg;
    color: $hover-color;
  }
}

/**
 * Text color: add required declarations to have all text within element in defined color
 */
@mixin text-color($color) {
  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $color;
  }
}

/**
 * Text-shadow mixin, apply branded text-shadow within element
 */

@mixin text-shadow {
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.9);

  .btn {
    text-shadow: none;
  }
}

/**
 * Remove underline from selected objects
 */

@mixin nounderline {
  text-decoration: none !important;
}

/**
 * Have the element be a generic 100% width light-gray block
 */

@mixin light-gray-block {
  background: $light;
  display: block;
  width: 100%;
  border-radius: $border-radius-lg;
  padding-left: 20px;
  padding-right: 20px;
}

/**
 * Add a black overlay behind an item
 */

@mixin overlay($background: rgba(0, 0, 0, 0.45)) {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &:after {
    background: $background;
    content: " ";
    display: block;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}
