/**
 * Banner for first investment guarantee.
 */

.fig-banner {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f5f5f5;
  a {
    color: $teal;
    text-decoration: underline;
  }

  @include media-breakpoint-up(md) {
    padding: 8px 16px;
    text-align: center;
  }
}