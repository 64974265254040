/**
 * Adds a coloured circle (default blue) behind an icon, making the icon white
 */

.icon-circle {
  background-color: $dark;
  border-radius: 50%;
  color: $white;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;

  // Sized versions
  &.icon-lg {
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 30px;

    @media (max-width: map-get($grid-breakpoints, "xs")) {
      width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 18px;
    }
  }

  &.icon-full {
    width: 100%;
    height: 0;
    line-height: 0;
    padding-top: 50%;
    padding-bottom: 50%;
  }
}
