// Adds styling for forms and input elements

.right-container {
  max-width: 500px;
}
.link-button {
  width: 164px;
  height: 36px;
  padding: 8px 14px;
  margin-left: auto;
  gap: 8px;
  border-radius: 2px 0 0 0;
  background: url("images/icons/icon-square-left-top-arrow.svg") no-repeat, #FFFFFF;
  background-position: right 0.5em center;
  border: 1px solid $gray-300;
  box-shadow: $smooth-shadow-sm;
  line-height: 18px;
  font-weight: 400;
}
.box {
  padding: 24px;
  gap: 16px;
  border-radius: 32px;

  margin-bottom: 20px;
  border: 1px solid $gray-200;

  box-shadow: $smooth-shadow-sm;

  .frame {
    display: flex;

    .inner-box {
      padding: 24px;
      margin-right: 4px;
      margin-left: 4px;
      gap: 16px;
      border-radius: 32px;

      border: 1px solid $gray-200;
      background-color: $gray-100;


      box-shadow: $smooth-shadow-sm;

      flex: 1;
      .top-row {
        color: $teal-600;
        display: flex;
        align-items: flex-end;
        .number {
          font-size: 30px;
          font-weight: 400;
          margin-right: 8px;
        }
        .title {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 4px;
        }

      }
      .subtitle {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        color: $gray-600;
      }

    }
  }

}
.warning-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: $input-warning-text;


  &::before {
    content: "!";
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    width: 20px;
    height: 20px;
    color: $input-warning-text;
    border: 2px solid $input-warning-text;
    border-radius: 50%;
    text-align: center;
    margin-right: 5px;
    padding-left: 1px;
  }
}
.toggle-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 16px;

  background: $green-50;

  border: 1px solid $green-200;
  box-shadow: $smooth-shadow-sm;

  .turned-on {
    background: $white;
  }

  .text {
    width: 300px;
    height: 20px;

    font-weight: 800;
    line-height: 20px;
    text-align: left;
    margin-left: 20px;
    padding: 3px;

    color: $deep-green;

  }
  .supporting-text {
    font-weight: 200;
  }

  .toggle {
    width: 36px;
    height: 20px;
  }

}

.number-field {
  height:  calc( 1.5em + 0.55rem + 2px );
  width: 25%;
  font-weight: inherit;
}

/* Hide the spinner for Google Chrome & Safari & Microsoft Edge*/
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner for Firefox */
.no-spinners {
  -moz-appearance: textfield;
}

/* The switch - the box around the slider */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: 3px;
}

.toggle-switch input { display: none; }

/* The slider */
.toggle-switch span.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $gray-400;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
  border: 2px solid $green-600;
}

input:checked + .slider {
  background-color: $green-600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  border: 2px solid $green-600;
}