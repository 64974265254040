/*
  Navbar, mainly adds to bootstraps navbar
*/

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-fixed;
  font-family: $navbar-font-family;
  font-size: $navbar-font-size;

  hr.brandline {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $navbar-brandline-height;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: $zindex-fixed + 1;
    background: $white;
    border: none;
  }

  .navbar-brand {
    line-height: 1;

    .logo {
      height: $navbar-brand-height;
      width: auto;
    }
  }

  .navbar-toggler {
    line-height: 0;
    border: none;
  }

  .navbar-nav {
    .nav-item.active {
      .nav-link {
        font-weight: $font-weight-bolder;
      }
    }

    .nav-link {
      padding-top: $navbar-nav-link-padding-y;
      padding-bottom: $navbar-nav-link-padding-y;
    }

    .nav-indicator {
      background-color: $gray-light;
      border: 1px solid $gray-300;
      padding: 4px 12px 4px 8px;
      margin-right: 0.75em;
      height: 2em;
      font-weight: $font-weight-medium;

      .indicator-off {
        color: #7a7a7a;
      }
    }
  }

  .nav-unset-padding {
    margin-top: -$navbar-padding-y;
    margin-bottom: -$navbar-padding-y;
    padding-top: $nav-link-padding-y + $navbar-padding-y;
    padding-bottom: $nav-link-padding-y + $navbar-padding-y;
  }

  .dropdown-menu-custom {
    width: 400px;
  }
}