#cookie-consent-container {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0rem;
  right: 0rem;
  bottom: 0rem;
  z-index: 100;
  @include media-breakpoint-up(sm) {
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
  }
  & > div {
    max-width: 768px;
    & > div {
      & > div:first-child {
        padding-right: 24px;
      }
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 16px;
      text-align: left;
      border-radius: 16px 16px 0 0;
      background-color: $deep-green;
      box-shadow: 0px 0.49811px 2.21381px 0px rgba(0, 0, 0, 0.02),
        0px 1.19702px 5.32008px 0px rgba(0, 0, 0, 0.03),
        0px 2.25388px 10.01724px 0px rgba(0, 0, 0, 0.04),
        0px 4.02054px 17.86905px 0px rgba(0, 0, 0, 0.04),
        0px 7.51997px 33.42209px 0px rgba(0, 0, 0, 0.05),
        0px 18px 80px 0px rgba(0, 0, 0, 0.07);
      @include media-breakpoint-up(sm) {
        padding: 24px;
        border-radius: 8px;
      }
    }
  }
  button[aria-label="Close"] {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    transform: none;
    border-radius: 4px;
    background: rgba(white, 0.08);
  }
}

.cookie-consent {
  &__banner-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-bottom: 24px;
    @include media-breakpoint-up(sm) {
      margin: 0 -26px -12px 0;
      flex-direction: row;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }
  &__button {
    -webkit-appearance: none;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    padding: 0.75rem 1rem;
    @include media-breakpoint-up(sm) {
      padding: 0.33rem 0.75rem;
      font-size: 14px;
    }
    &__primary {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      background-color: $teal;
      color: $white;
      border: 1px solid $teal;
      &:hover {
        background-color: rgba($teal, 0.9);
      }
    }
    &__secondary {
      background-color: transparent;
      color: rgba($white, 0.8);
      border: 1px solid rgba($white, 0.8);
      &:hover {
        background-color: rgba($white, 0.2);
      }
    }
  }
}
