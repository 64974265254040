/**
 * Mobile menu styling
 */

.mobile-menu {
  height: 100vh;
  z-index: 9999;
  width: 90vw;
  max-width: 280px;

  padding: calc($grid-gutter-width / 2);
  overflow: hidden;
  display: block;
  position: fixed;
  overflow: auto;

  top: 0;
  bottom: 0;

  transition-property: left, right, background, visibility;
  transition-duration: 0.3s;
  transition-delay: 0;
  transition-timing-function: ease;

  visibility: hidden;
  background-color: $mobile-menu-bg;

  @include text-color($mobile-menu-color);

  @media (min-width: $mobile-menu-breakpoint) {
    display: none;
    visibility: hidden;
  }

  &.open {
    @media (max-width: $mobile-menu-breakpoint) {
      visibility: visible;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      &.overlay {
        background: rgba($dark, 0.85);
        visibility: visible;
      }
    }
  }

  &.left {
    left: -280px;
  }

  &.right {
    right: -280px;
  }

  &.overlay {
    background: rgba(0, 0, 0, 0);
    z-index: 9000;
    max-width: 100vw;
    width: 100vw;
  }

  a {
    color: $mobile-menu-color;
  }

  .padding-offset {
    margin: -(calc($grid-gutter-width / 2));
  }

  ul.menu-list {
    margin-bottom: 0;
    margin-left: -(calc($grid-gutter-width / 3));
    padding-left: calc($grid-gutter-width / 3);

    ul.menu-list {
      margin-left: 0;
      border-left: 1px solid $dark;
    }

    li {
      list-style-type: none;

      &.active {
        font-weight: $font-weight-bolder;
      }

      .menu-item {
        display: block;
        margin-left: -(calc($grid-gutter-width / 2));
        margin-right: -(calc($grid-gutter-width / 2));
        padding-left: calc($grid-gutter-width / 2);
        padding-right: calc($grid-gutter-width / 2);
        padding-top: calc($grid-gutter-width / 3);
        padding-bottom: calc($grid-gutter-width / 3);
      }
    }
  }
}
