// Adds custom styling to new image offset shapes //

.rectangle-left {
  position: relative;
  display: inline-block;
}

.rectangle-left::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 28rem;
  max-height: 19rem;
  top: -28px;
  right: 27px;
  border: 1px solid $dark;
  z-index: -10;
  position: absolute;
}

.rectangle-left-large {
  position: relative;
  display: inline-block;
}

.rectangle-left-large::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 35rem;
  max-height: 24rem;
  top: -30px;
  right: 30px;
  border: 1px solid $dark;
  z-index: -10;
  position: absolute;
}

.rectangle-right {
  position: relative;
  display: inline-block;
}

.rectangle-right::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 28rem;
  max-height: 19rem;
  top: -28px;
  left: 27px;
  border: 1px solid $dark;
  z-index: -10;
  position: absolute;
}

.rectangle-right-down {
  position: relative;
  display: inline-block;
}

.rectangle-right-down::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 28rem;
  max-height: 19rem;
  bottom: -28px;
  left: 27px;
  border: 1px solid $dark;
  z-index: -10;
  position: absolute;
}

.rectangle-right-large {
  position: relative;
  display: inline-block;
}

.rectangle-right-large::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 35rem;
  max-height: 24rem;
  top: -30px;
  left: 30px;
  border: 1px solid $dark;
  z-index: -10;
  position: absolute;
}

.rectangle-right-large-red {
  position: relative;
  display: inline-block;
}

.rectangle-right-large-red::before {
  content: '';
  width: 100%;
  height: 100%;
  max-width: 35rem;
  max-height: 24rem;
  top: -30px;
  left: 30px;
  border: 1px solid $primary;
  z-index: -10;
  position: absolute;
}

.rectangle-border {
  border: 1px solid $dark;
}

@media (max-width: 575.98px) {
  .rectangle-left {
    position: relative;
    display: inline-block;
  }

  .rectangle-left::before {
    content: '';
    display: none;
  }

  .rectangle-left-large {
    position: relative;
    display: inline-block;
  }

  .rectangle-left-large::before {
    content: '';
    display: none;
  }

  .rectangle-right {
    position: relative;
    display: inline-block;
  }

  .rectangle-right::before {
    content: '';
    display: none;
  }

  .rectangle-right-large {
    position: relative;
    display: inline-block;
  }

  .rectangle-right-large::before {
    content: '';
    display: none;
  }

  .rectangle-border {
    border: 0;
  }
}
