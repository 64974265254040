/**
 * Cards
 *
 * Adds functionality to the base bootstrap cards element
 */

.card {
  &.selected {
    background-color: $primary;
    color: $white;
  }

  .card-footer {
    background: $white;
  }
}
