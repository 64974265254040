/**
 * Simple utility classes to be used in combination with bootstraps'
 * .table element
 */

 .table {
  &.table-stripeless {
    td {
      border-top: none;
    }
  }
}

.styled-table {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #efefef;
    border-radius: 16px;
    background: white;
    box-shadow: 0px 6px 6px -3px rgba(13, 33, 32, 0.03),
      0px 5px 4px -4px rgba(13, 33, 32, 0.06);
    z-index: -1;
  }
  tr {
    &:first-child {
      td,
      th {
        border-top: none;
      }
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  td,
  th {
    padding: 12px 24px;
  }
  th {
    background-color: #f3f3f5;
    border-radius: 16px 16px 0px 0px;
  }
}
