/**
 * Define site-wide structural elements
 * All elements are assumed to have a specific #selector
 * All subsequent rules are within the #selector
 */

/**
 * Responsive font sizes
 */

html {
  @include media-breakpoint-up(xs) {
    font-size: 14px;
  }
  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 17px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}
