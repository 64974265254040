/**
 * Utility classes specifically for links
 */

.no-link-styling {
  &,
  a {
    &,
    &:hover,
    &:active {
      color: unset !important;
      text-decoration: unset !important;
    }
  }
}

.link {
  color: $teal;
}

.footer {
  a {
    color: $dark !important;
  }
}
