/**
 * reCAPTCHA Overrides
 */

// override used to hiding reCAPTCHA badge
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
    display: none;
    visibility: hidden;
}
