/**
 * Define special font-sizes.
 * In certain cases we need responsive font styles beyond the default.
 * E.g., the small font would be too small on mobile if we'd use the bootstrap ".small" class.
 */

@each $screen, $width in $grid-breakpoints {
  @if $screen == "xs" {
    @each $name, $size in $typographical-sizes {
      .text-#{$name} {
        font-size: $size;
      }
    }
  } @else {
    @include media-breakpoint-up($screen) {
      @each $name, $size in $typographical-sizes {
        .text-#{$screen}-#{$name} {
          font-size: $size;
        }
      }
    }
  }
}

.text {
  &__teal {
    color: $teal;
  }
  &__coral {
    color: $coral;
  }
  &__deep-green {
    color: $deep-green;
  }
  &__gray {
    color: $gray;
  }
  &__gray-light {
    color: $gray-light;
  }
  &__gray-900 {
    color: $gray-900;
  }
  &__gray-800 {
    color: $gray-800;
  }
  &__gray-700 {
    color: $gray-700;
  }
  &__gray-600 {
    color: $gray-600;
  }
  &__gray-500 {
    color: $gray-500;
  }
  &__gray-400 {
    color: $gray-400;
  }
  &__gray-200 {
    color: $gray-200;
  }
  &__gray-100 {
    color: $gray-100;
  }
  &__decorative-underline {
    position: relative;
    cursor: help;
    &:after {
      position: absolute;
      bottom: 2px;
      left: 0px;
      right: 0px;
      content: "";
      display: block;
      // Border botton 1px dashed with the current text color
      border-bottom: 1px dashed currentColor;
      opacity: 0.4;
    }
  }
  &__lowercase {
    text-transform: lowercase;
  }
}
