/**
 * Define utility classes for height and with
 *
 * min-height-* will define a min-height on the element
 * min-width-* will define a min-width on the element
 * max-height-* will define a max-height on the element
 * max-width-* will define a max-width on the element
 *
 * * can be all elements from $sizes
 */

@each $name, $size in $sizes {
  .min-height-#{$name} {
    min-height: $size;
  }
}

@each $name, $size in $sizes {
  .min-width-#{$name} {
    min-width: $size;
  }
}

.min-width-0 {
  min-width: 0;
}

@each $name, $size in $sizes {
  .max-height-#{$name} {
    max-height: $size;
  }
}

@each $name, $size in $sizes {
  .max-width-#{$name} {
    max-width: $size;
  }
}

@each $name, $size in $gap-sizes {
  .gap-#{$name} {
    gap: $size;
  }

  .gap-x-#{$name} {
    row-gap: $size;
  }

  .gap-y-#{$name} {
    column-gap: $size;
  }
}

@each $screen, $width in $grid-breakpoints {
  @if $screen =="xs" {
    @each $name, $size in $gap-sizes {
      .space-y-#{$name}>*:not(:last-child) {
        margin-bottom: $size;
      }
    }
  }

  @else {
    @include media-breakpoint-up($screen) {
      @each $name, $size in $gap-sizes {
        .space-y-#{$screen}-#{$name}>*:not(:last-child) {
          margin-bottom: $size;
        }
      }
    }
  }
}

.max-w-100 {
  max-width: 100%;
}