.pill-tabs {
  display: flex;
  gap: 12px;
  margin-bottom: 0;
  padding-left: 15px;
  overflow-x: scroll;
  .tab.list-inline-item {
    &:not(:last-child) {
      margin-right: 0;
    }
    a {
      display: flex;
      align-items: center;
      padding: 8px 24px;
      color: $gray-600;
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-medium;
      border: 1px solid $gray-200;
      border-radius: 2px;
      background-color: white;
      white-space: nowrap;
      &:focus,
      &:hover {
        color: $gray-700;
        text-decoration: none;
        background-color: $gray-100;
      }
      &:focus {
        outline: 1px dotted $teal;
      }
      &.active {
        color: white;
        font-weight: $font-weight-bold;
        background-color: $teal;
        border-color: $teal;
        &:hover {
          color: rgba(white, 0.85);
          background-color: darken($teal, 2%);
        }
        .badge {
          color: $teal;
          background-color: #c3f2f5;
        }
      }
    }
  }
  .badge {
    min-width: 26px;
    margin-right: -12px;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 18px;
    background-color: rgba($flat-tabs-badge-bg-color, 0.5);
    color: $flat-tabs-badge-color;
  }
}
