.country-box {
  overflow: hidden;
  .img-icon {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: cover;
    &.pr-1 {
      padding: 0 !important;
    }
  }
  &__image {
    position: relative;
    max-height: 276px;
    margin: -1.5rem -1.25rem 0 -1.25rem;
    padding-bottom: 42%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    display: grid;
    gap: 24px;
    @include media-breakpoint-up(md) {
      gap: 24px;
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(xl) {
      gap: 72px;
      grid-template-columns: 1fr 1fr;
    }
  }
}
